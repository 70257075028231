export default class VinculoEntidadeService {
  constructor(http) {
    this._http = http
  }

  async getListaVinculosDaEntidade(entidadeId) {
    const { data } = await this._http.get(
      `/api/vinculos-entidade/${entidadeId}`,
    )
    return data
  }

  async saveVinculoDaEntidade(vinculoEntidade) {
    if (vinculoEntidade.id) {
      return await this._http.put('/api/vinculos-entidade', vinculoEntidade)
    } else {
      return await this._http.post('/api/vinculos-entidade', vinculoEntidade)
    }
  }
}
